import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMediums(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/marketing/medium", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchMedium(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/marketing/medium/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addMedium(ctx, mediumData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/marketing/medium", { medium: mediumData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
