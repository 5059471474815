<template>
  <div id="medium">
    <mediums-list />
  </div>
</template>

<script>
import MediumsList from "./mediums-list/MediumsList.vue";

export default {
  name: "Medium",
  components: {
    MediumsList,
  },
};
</script>
