<template>
  <b-sidebar
    id="add-new-medium-sidebar"
    :visible="isAddNewMediumSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-medium-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Add Medium
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Medium -->
          <validation-provider
            #default="validationContext"
            name="Medium"
            rules="required"
          >
            <b-form-group
              label="Medium"
              label-for="medium"
            >
              <b-form-input
                id="medium"
                v-model="mediumData.medium"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Medium"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Spent Budget -->
          <validation-provider
            #default="validationContext"
            name="Spent Budget"
            rules="required"
          >
            <b-form-group
              label="Spent Budget"
              label-for="spent-budget"
            >
              <b-form-input
                id="spent-budget"
                v-model="mediumData.spentBudget"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Spent Budget"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Channel"
            rules="required"
          >
            <b-form-group
              label="Select channel"
              label-for="channel"
            >
              <v-select
                id="channel"
                v-model="mediumData.selectedChannel"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="channel"
                :clearable="false"
                class="per-page-selector d-inline-block ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMediumSidebarActive",
    event: "update:is-add-new-medium-sidebar-active",
  },
  props: {
    isAddNewMediumSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      channel: ["Facebook", "Twitter"],
      selectedChannel: "",
      spentBudget: "",
    };
  },
  setup(props, { emit }) {
    const blankMediumData = {
      medium: "",
      selectedChannel: "",
      spentBudget: "",
    };

    const mediumData = ref(JSON.parse(JSON.stringify(blankMediumData)));
    const resetmediumData = () => {
      mediumData.value = JSON.parse(JSON.stringify(blankMediumData));
    };

    const onSubmit = () => {
      store.dispatch("app-medium/addMedium", mediumData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-medium-sidebar-active", false);
      });
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmediumData);

    return {
      mediumData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-medium-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
