import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useMediumsList() {
  // Use toast
  const toast = useToast();

  const refMediumListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "medium", sortable: true },
    { key: "selectedChannel", sortable: true },
    { key: "spentBudget", sortable: true },
  ];
  const perPage = ref(10);
  const totalMediums = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refMediumListTable.value ? refMediumListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMediums.value,
    };
  });

  const refetchData = () => {
    refMediumListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchMediums = (ctx, callback) => {
    store
      .dispatch("app-medium/fetchMediums", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { mediums, total } = response.data;

        callback(mediums);
        totalMediums.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching medium list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchMediums,
    tableColumns,
    perPage,
    currentPage,
    totalMediums,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMediumListTable,
    refetchData,
  };
}
